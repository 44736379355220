export type Role = 'doctor' | 'patient';

export interface Calendar {
  firstName: string;
  lastName: string;
  start: string;
  title: string;
}

export class User {
  public connections = 0;
  public doctor = {
    avatar: '',
    email: '',
    firstName: '',
    lastName: '',
    loggedIn: 0
  };
  public now: string;
  public participants = 0;
  public patient = {
    avatar: '',
    email: '',
    firstName: '',
    lastName: '',
    loggedIn: 0
  };
  public role: Role;
  public schedule: Calendar[];
  public status = '';
  public title = '';

  public firstName = '';
  public lastName = '';
  public avatar = '';
  public callerAvatar = '';
  public start = '';
  public joinTime = 0;
  public logoUrl='';
  public colorPrimary;
  public colorSecondary;
  public chat_access;
  public documents_access;
  public sketch_access;
  public notes_access;

  static fromJson(json): User {
    return Object.assign(new User, json);
  }
}

export class CallerSettings {
  public token: string;
  public room = false;
  public isMirrored = false;
}
