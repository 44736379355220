import { Component, OnDestroy,Output,EventEmitter } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-videochat',
  templateUrl: './videochat.component.html',
})

export class VideochatComponent
  implements OnDestroy {

  isDesktop: boolean;

        @Output() toggleActionBar = new EventEmitter<string>();

  constructor(deviceDetectorService: DeviceDetectorService,
              private settingsService: SettingsService) {
    this.isDesktop = deviceDetectorService.isDesktop();
  }

  ngOnDestroy() {
    this.settingsService.isOpened = false;
  }

    toggleActionBarVisibility(){
      this.toggleActionBar.emit();
    }
}
