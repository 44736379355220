import { ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { SettingsService } from '../../../services/settings.service';
import { PopupService } from '../../../services/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeAbstract } from '../../abstracts/unsubscribe.abstract';

const _AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext || (window as any).mozAudioContext;

export abstract class SettingsAbstractComponent
  extends UnsubscribeAbstract
  implements OnInit, OnDestroy {

  @ViewChild('testVideo', {static: true}) _testVideoRef: ElementRef;

  volumeValue = 100;

  audioContext = new _AudioContext();
  gainNode = this.audioContext.createGain();

  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isSafari: boolean;
  streamFailed: boolean;
  audioFailed: boolean;

  videoErroredDevice: any;
  audioErroredDevice: any;

  constructor(public userService: UserService,
              protected settingsService: SettingsService,
              protected popupService: PopupService,
              protected translate: TranslateService,
              protected deviceDetectorService: DeviceDetectorService) {
    super();

    this.isMobile = this.deviceDetectorService.isMobile();
    this.isTablet = this.deviceDetectorService.isTablet();
    this.isDesktop = this.deviceDetectorService.isDesktop();
    this.isSafari = this.deviceDetectorService.browser === 'Safari';

    this.settingsService
      .observeSettings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(settings => {
        this.volumeValue = settings.microVolume;
      });

    this.settingsService
      .getStream()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        stream => {
          if (stream && this._testVideoRef) {
            this.streamFailed = false;
            this._testVideoRef.nativeElement.srcObject = stream;
            this._testVideoRef.nativeElement.muted = true;
          } else {
            this.streamFailed = true;
          }

        },
        error => {
          this.streamFailed = true;
          console.log('Error getting stream: ', error);
        });
  }

  public get videoSelect() {
    return this.settingsService.videoSelect;
  }

  public set videoSelect(value: MediaDeviceInfo) {
    this.settingsService.videoSelect = value;
  }

  public get audioSelect() {
    return this.settingsService.audioSelect;
  }

  public set audioSelect(value: MediaDeviceInfo) {
    this.settingsService.audioSelect = value;
  }

  public get audioInputDevices() {
    return this.settingsService.audioInputDevices;
  }

  public get videoDevices() {
    return this.settingsService.videoDevices;
  }

  ngOnInit() {
    sessionStorage.removeItem('wasInTheCall');

    if (this.userService.page && (this.userService.page.getValue() === 'waitingRoom' || !this.userService.page.getValue())) {
      this.userService.setPage('settings');
    }

    this.settingsService
      .mediaError$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((mediaError) => {
        this.videoErroredDevice = mediaError.videoErroredDevice;
        this.audioErroredDevice = mediaError.audioErroredDevice;
      });

    if (navigator.userAgent.includes('Chrome')) {

      Promise.all([
        navigator['permissions'].query({name: 'camera'}),
        navigator['permissions'].query({name: 'microphone'})
      ])
        .then(([{state: videoState}, {state: audioState}]) => {

          if (videoState === 'denied' || audioState === 'denied') {

            setTimeout(() => {
              this.streamFailed = videoState === 'denied';
              this.audioFailed = audioState === 'denied';
              this.settingsService.getMedia();
              this.popupService.openErrorNotification(this.translate.instant('popups.deniedPermission'));
            }, 100);

          } else {

            if (videoState === 'prompt' || audioState === 'prompt') {
              setTimeout(() => {
                this.popupService.openErrorNotification(this.translate.instant('popups.promptPermission'));
              }, 0);
            }

            this.settingsService.getDevicesList();
            this.settingsService.getMedia();
            this.handleChangeVolume();
          }

        });

    } else {
      this.settingsService.getDevicesList();
      this.settingsService.getMedia();
      this.handleChangeVolume();
    }
  }

  handleChangeVolume() {
    this.gainNode.gain.value = this.volumeValue;

    this.settingsService.updateSettings({
      ...this.settingsService.getSettings(),
      microVolume: this.volumeValue
    });
  }

  handleChangeAudio(e) {
    this.settingsService.setAudioDevice(e);
    this.settingsService.getMedia();
  }

  handleChangeVideo(e) {
    this.settingsService.setVideoDevice(e);
    this.settingsService.getMedia();
  }

  ngOnDestroy() {
    this.settingsService.clearStream();
    super.ngOnDestroy();
  }
}
