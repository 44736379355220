import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { Settings } from '../models/settings';
import {PopupService} from './popup.service';
import {TranslateService} from '@ngx-translate/core';
import { first, switchMap, tap } from 'rxjs/internal/operators';
import { fromPromise } from 'rxjs/internal-compatibility';

declare const easyrtc: any;

interface ErroredDevices {
  videoErroredDevice?: boolean | MediaTrackConstraints;
  audioErroredDevice?: boolean | MediaTrackConstraints;
}

@Injectable()
export class SettingsService {

  private settings$ = new BehaviorSubject<Settings>(new Settings());
  private savedSettings: Settings;
  private _audioInputDevices: MediaDeviceInfo[] = [];
  private _videoDevices: MediaDeviceInfo[] = [];

  private _mediaError$ = new Subject<ErroredDevices>();

  private stream: MediaStream = null;
  private streamSub = new BehaviorSubject<MediaStream>(null);

  audioSelect: MediaDeviceInfo;
  videoSelect: MediaDeviceInfo;

  isDeviceChanged = false;

  private _isOpened = false;
  set isOpened(value: boolean) {
    if (value) {
      this.savedSettings = Object.assign(this.getSettings());
    } else {
      console.log('save settings');
      this.updateSettings(this.settings$.getValue());
    }

    this._isOpened = value;
  }

  get isOpened() {
    return this._isOpened;
  }

  constructor(private popupService: PopupService,
              private translate: TranslateService) {
    this.getDevicesList();
  }

  clearStream() {
    if (this.stream) {
      this.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
  }

  get mediaError$() {
    return this._mediaError$.asObservable();
  }

  get audioInputDevices() {
    return this._audioInputDevices;
  }

  get videoDevices() {
    return this._videoDevices;
  }

  setDefaultVideoDevice() {
    if (this._videoDevices) {
      this.videoSelect = this._videoDevices[0];
    }
  }

  getSavedSettings() {
    return this.savedSettings;
  }

  setDefaultAudioInputDevice() {
    if (this._audioInputDevices) {
      this.audioSelect = this._audioInputDevices[0];
    }
  }

  getStream() {
    return this.streamSub.asObservable();
  }

  getDevicesList() {
    easyrtc.getAudioSourceList(this.getAudioDevices.bind(this));
    easyrtc.getVideoSourceList(this.getVideoDevices.bind(this));
  }

  getMedia(constraints?: MediaStreamConstraints) {
    this.clearStream();

    if (!constraints) {
      constraints = {
        audio: {deviceId: this.audioSelect && this.audioSelect.deviceId},
        video: true,
      };
    }

    if (this.videoSelect && this.videoSelect.deviceId) {
      constraints.video = {
        deviceId: {
          ideal: this.videoSelect && this.videoSelect.deviceId,
          exact: this.videoSelect && this.videoSelect.deviceId
        },
      };
    }

    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream: MediaStream) => {
        this.stream = stream;
        this.streamSub.next(stream);
        this.enumerateDevices(stream);
        this._mediaError$.next({
          videoErroredDevice: false,
          audioErroredDevice: false
        });
      })
      .catch((error) => {
        console.log('Settings navigator.mediaDevices.getUserMedia error ', error, constraints);
        const hasVideoError = error.toString().includes('video');
        const hasAudioError = error.toString().includes('audio');

        this._mediaError$.next({
          videoErroredDevice: hasVideoError ? constraints.video : false,
          audioErroredDevice: hasAudioError ? constraints.audio : false
        });

        if (error.name === 'NotAllowedError') {
          setTimeout(() => {
            this.popupService
              .openErrorNotification(
                this.translate
                  .instant(navigator.userAgent.includes('Chrome') ? 'popups.deniedPermission' : 'popups.deniedPermissionFF'));
            }, 0);

            // Microphone permissions
            (navigator as any).permissions
              .query({name: 'microphone'})
              .then((permissionObj) => {
                console.log('Mic permissions: ', permissionObj.state);
                this._mediaError$.next({
                  audioErroredDevice: permissionObj.state === 'denied'
                });
              })
              .catch((micError) => {
                console.log('Got micError :', micError);
                this._mediaError$.next({
                  audioErroredDevice: true
                });
              });

            // Camera permissions
            (navigator as any).permissions
              .query({name: 'camera'})
              .then((permissionObj) => {
                console.log('Cam permissions: ', permissionObj.state);
                this._mediaError$.next({
                  videoErroredDevice: permissionObj.state === 'denied'
                });
              })
              .catch((camError) => {
                console.log('Got camError :', camError);
                this._mediaError$.next({
                  videoErroredDevice: true
                });
              });
        }

        const audioConstraints = {
          audio: constraints.audio,
          video: false
        };
        const videoConstraints = {
          video: constraints.video,
          audio: false
        };

        if (!hasAudioError) { // Getting an audio stream in case of video error
          navigator.mediaDevices
            .getUserMedia(audioConstraints)
            .then((audioStream: MediaStream) => {
              this.stream = audioStream;
              this.streamSub.next(audioStream);
            })
            .catch((audioError) => {
              console.log('Settings navigator.mediaDevices.getUserMedia audioError ', audioError, audioConstraints);
            });
        }

        if (!hasVideoError) { // Getting a video stream in case of audio error
          navigator.mediaDevices
            .getUserMedia(videoConstraints)
            .then((videoStream: MediaStream) => {
              this.stream = videoStream;
              this.streamSub.next(videoStream);
            })
            .catch((videoError) => {
              console.log('Settings navigator.mediaDevices.getUserMedia videoError ', videoError, videoConstraints);
            });
        }
    });
  }

  setAudioDevice(device: MediaDeviceInfo) {
    this.audioSelect = device;
    this.isDeviceChanged = true;
    sessionStorage.setItem('microphone', device.deviceId);

    const settings = this.getSettings();
    this.updateSettings({
      ...settings,
      audioDevice: device
    });
  }

  setVideoDevice(device: MediaDeviceInfo) {
    this.videoSelect = device;
    this.isDeviceChanged = true;
    sessionStorage.setItem('camera', device.deviceId);

    const settings = this.getSettings();
    this.updateSettings({
      ...settings,
      videoDevice: device,
    });
  }

  updateSettings(value: Settings) {
    this.settings$.next(value);
  }

  getSettings() {
    return this.settings$.getValue();
  }

  observeSettings() {
    return this.settings$.asObservable();
  }

  restoreSettings() {
    return of(1)
      .pipe(
        tap(() => {
          const settings = this.getSavedSettings();

          this.updateSettings(settings);

          if (settings.videoDevice) {
            this.setVideoDevice(settings.videoDevice);
          } else {
            this.setDefaultVideoDevice();
          }

          if (settings.audioDevice) {
            this.setAudioDevice(settings.audioDevice);
          } else {
            this.setDefaultAudioInputDevice();
          }
        }),
        switchMap(() => fromPromise(this.getMedia())),
        switchMap(() => this.getStream()),
        first(),
      );
  }

  private enumerateDevices(stream: MediaStream) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('enumerateDevices() is not supported. Skipping devices list in the settings.');
      return;
    }
    const selectedVideo = stream.getVideoTracks()[0];
    const selectedAudio = stream.getAudioTracks()[0];

    // 'enumerateDevices' is not supported on iOS Chrome
    navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        this._audioInputDevices = [];
        this._videoDevices = [];

        for (const mediaDeviceInfo of devices) {
          if (mediaDeviceInfo.kind === 'audioinput') {
            this._audioInputDevices.push(mediaDeviceInfo);
            if (selectedAudio.label === mediaDeviceInfo.label) {
              this.audioSelect = mediaDeviceInfo;
            }
          }
          if (mediaDeviceInfo.kind === 'videoinput') {
            this._videoDevices.push(mediaDeviceInfo);
            if (selectedVideo.label === mediaDeviceInfo.label) {
              this.videoSelect = mediaDeviceInfo;
            }
          }
        }

        this.applySelects();
      })
      .catch(err => console.log(err));
  }

  private applySelects() {

    if (!(this.audioSelect || sessionStorage.microphone)) {
      this.setDefaultAudioInputDevice();
    } else {
      this.audioSelect = this._audioInputDevices.find(
        device => device.deviceId === (this.audioSelect ? this.audioSelect.deviceId : sessionStorage.microphone)
      );
    }

    if (!(this.videoSelect || sessionStorage.camera)) {
      this.setDefaultVideoDevice();
    } else {
      this.videoSelect = this._videoDevices.find(
        device => device.deviceId === (this.videoSelect ? this.videoSelect.deviceId : sessionStorage.camera)
      );
    }

    const newSettings = Object.assign(this.getSettings(),
      {
        audioId: this.audioSelect.deviceId,
        videoId: this.videoSelect.deviceId
      }
    );

    this.updateSettings(newSettings);
  }

  private getAudioDevices(deviceList: MediaDeviceInfo[]) {
    this._audioInputDevices = deviceList;
    this.applySelects();
  }

  private getVideoDevices(deviceList: MediaDeviceInfo[]) {
    this._videoDevices = deviceList;
    this.applySelects();
  }
}
