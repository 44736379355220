import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SettingsService } from '../../../services/settings.service';
import { UserService } from '../../../services/user.service';
import { PopupService } from '../../../services/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VideoStreamingService } from '../../../services/video-streaming.service';
import { take } from 'rxjs/operators';
import { SettingsAbstractComponent } from '../abstracts/settings.abstract.component';

@Component({
  selector: 'app-settings-mobile',
  templateUrl: './settings-mobile.component.html',
  styleUrls: ['./settings-mobile.component.css']
})
export class SettingsMobileComponent
  extends SettingsAbstractComponent
  implements OnInit, OnDestroy {

  @Output() isSettingsDisplayed = new EventEmitter<boolean>();

  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;

  constructor(public userService: UserService,
              protected settingsService: SettingsService,
              protected popupService: PopupService,
              protected translate: TranslateService,
              protected deviceDetectorService: DeviceDetectorService) {
    super(userService, settingsService, popupService, translate, deviceDetectorService);
  }

  saveSettings() {
    this.isSettingsDisplayed.emit(false);
  }
}
