import { Component } from '@angular/core';
import { SettingsAbstractComponent } from '../abstracts/settings.abstract.component';
import { UserService } from '../../../services/user.service';
import { SettingsService } from '../../../services/settings.service';
import { PopupService } from '../../../services/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-settings-desktop',
  templateUrl: './settings-desktop.component.html',
  styleUrls: ['./settings-desktop.component.css']
})
export class SettingsDesktopComponent
  extends SettingsAbstractComponent {
  constructor(public userService: UserService,
              protected settingsService: SettingsService,
              protected popupService: PopupService,
              protected translate: TranslateService,
              protected deviceDetectorService: DeviceDetectorService) {
    super(userService, settingsService, popupService, translate, deviceDetectorService);
  }
}
