import { ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, NgZone, ElementRef} from '@angular/core';
import { VideoStreamingService } from '../../../services/video-streaming.service';
import { takeUntil } from 'rxjs/internal/operators';
import { SettingsService } from '../../../services/settings.service';
import { UserService } from '../../../services/user.service';
import { Role, User } from '../../../models/user';
import { PopupService } from '../../../services/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UnsubscribeAbstract } from '../../abstracts/unsubscribe.abstract';
import { interval, Subject } from 'rxjs';

const avatarDefault = '../../../assets/icons/user.png';

export abstract class VideochatAbstract
  extends UnsubscribeAbstract
  implements OnDestroy, AfterViewInit {

  @ViewChild('videoSelf', { static: false }) videoSelf: ElementRef<HTMLVideoElement>;
  @ViewChild('videoCaller', { static: false }) videoCaller: ElementRef<HTMLVideoElement>;

  myId: string;
  connectedClientsList: Array<string> = [];
  volumeVisible = false;
  volume = 100;
  screenSharingEnabled = false;
  videoMuteEnabled = false;
  actionBarEnabled=false;
  selfAvatar: string;
  callerAvatar: string;
  isConnected: boolean;
  isDoctor = false;
  role: Role;
  sketchIsActive: boolean;
  showCorrespondent = true;
  showAvatar = false;
  showSelf = true;
  isInvitationNeeded = false;
  isInvitationInProgress = false;
  isCallerMirrored = false;

  untilFinishedJoinTime = new Subject();

  isDesktop: boolean;
  audioEnter: HTMLAudioElement = new Audio('/assets/sounds/enterRoom.mp3');
  audioClose: HTMLAudioElement = new Audio('/assets/sounds/closeCall.mp3');
  protected constructor(private cdr: ChangeDetectorRef,
                        public streamingService: VideoStreamingService,
                        private settingsService: SettingsService,
                        public userService: UserService,
                        private popupService: PopupService,
                        private translate: TranslateService,
                        private deviceDetectorService: DeviceDetectorService,
                        private router: Router,
                        private ngZone: NgZone) {
    super();
    this.isDesktop = this.deviceDetectorService.isDesktop();


    this.userService.getUserSocket();

    this.streamingService
      .getMyId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(id => {
        this.myId = id;
        this.cdr.detectChanges();
      });

    this.streamingService
      .getConnectedClientsList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(client => {
        if (client) {
          this.connectedClientsList.push(client);
        } else {
          this.connectedClientsList = [];
        }

        this.connectedClientsList.forEach((clientId) => {
          if (this.isDoctor) {
            this.streamingService.performCall(clientId);
          }
        });
        this.cdr.detectChanges();
      });

    this.streamingService
      .observePartnerId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(id => {
        // disable screen sharing on plugging off the patient

        if (!id && this.isDoctor && this.screenSharingEnabled) {
          console.log('disable screen sharing on plugging off the patient');
          this.streamingService.stopScreenShare();
        }

        this.isConnected = !!id;
        this.cdr.detectChanges();
      });

    this.settingsService
      .observeSettings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(settings => {
        console.log(settings);
        this.volume = settings.videoVolume;
        this.streamingService.audioMuteEnabled = settings.audioMuteEnabled;
        this.streamingService.toggleAudio(!settings.audioMuteEnabled);
        this.videoMuteEnabled = settings.videoMuteEnabled;
        this.streamingService.videoMuteEnabled = settings.videoMuteEnabled;
        this.streamingService.toggleVideo(!settings.videoMuteEnabled);
      });

    this.streamingService
      .getScreenSharingStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(status => {
        this.screenSharingEnabled = status;
        this.cdr.detectChanges();
      });

    this.streamingService
      .observeSketchState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => this.sketchIsActive = state);

    this.streamingService
      .getMessages()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(messageData => {
        // The check is to ensure we can not send absolutely empty message, but still can send image
        // And to make sure we don't mistake messages from the sketch canvas for messages from the chat
        // update messages, if partner's list of messages is not empty

        if (messageData.sender !== 'me' && messageData.message.hasOwnProperty('camera')) {
          this.ngZone.run(() => {
            this.showAvatar = !messageData.message.camera;
            console.log('showAvatar ', this.showAvatar,
              'isConnected ', this.isConnected, 'showCorrespondent ', this.showCorrespondent, 'sketchIsActive ', this.sketchIsActive,
              'isConnected || (!this.showCorrespondent && !this.showAvatar) ', (this.isConnected || !this.showCorrespondent) && !this.showAvatar);
          });
        }
      });

    this.userService
      .callStatus$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value === true) {
          console.log('Enter room: ', value);

          this.audioEnter
            .play()
            .catch((error) => {
              console.log('audioEnter error ', error);

              // Try again one more time. It sometimes fails in Chrome despite autoplay being set previously
              // And in those cases delayed retry helps
              
              setTimeout(() => {

                this.audioEnter
                  .play()
                  .then(() => {
                    console.log('audioEnter retry success ');
                  })
                  .catch((retryError) => {
                    console.log('audioEnter retry error ', retryError);

                    /** If we refreshed the page and have not made any user interactions yet, we are going to need the workaround below
                     * to play notifications audio (e. g. like when someone entered the room) on iOS devices or in Safari on macOS
                     * That is because Apple has a policy not to autoplay any audio unless user interacted with the app first
                     * (like when pressing 'play')
                     * Programmatically invoking 'click()' on HTML elements does not work and this gives a lot of headache around the
                     * internet. The good news is, that we are going to need this only on Apple devices, only if the user refreshed
                     * the page inside the call, and there is a message to notify user that his action actually fixes notification problems.
                     */

                    window.onclick = () => {
                      this.playSound();
                      window.onclick = null;
                    };
                    
                    this.translate
                      .get('popups.allowSounds')
                      .subscribe(translation => {

                        this.ngZone.run(() => {
                            this.popupService
                              .openErrorDialog(translation)
                              .then(() => {
                                this.audioEnter.play(); // This will give permission error if we don't play the sound initiated by user click before
                              });
                          }
                        );

                      });
                    
                  });
              }, 500);
            });
        } else {
          console.log('Close call: ', value);
          this.audioClose.play();
        }
      });
  }

  playSound() {
    // Workaround for iOS browsers, which won't play any audio until one is played on user's gesture

    const audioWorkaround: HTMLAudioElement = new Audio('/assets/sounds/enterRoom.mp3');

    audioWorkaround.volume = 0; // Because it's a workaround
    audioWorkaround.play()
      .then(() => {
        console.log('audioWorkaround played successfully');
      })
      .catch((error) => {
        console.log('audioWorkaround play error', error);
      });
  }

  toggleCorrespondent() {
    this.showCorrespondent = !this.showCorrespondent;
  }

  toggleSelf() {
    this.showSelf = !this.showSelf;
  }

  videoIsHidden(): boolean {
    if (this.settingsService.videoSelect) {
      return !(this.myId || this.settingsService.videoSelect.deviceId) || this.videoMuteEnabled;
    } else {
      return !this.myId || this.videoMuteEnabled;
    }
  }

  inviteToTheRoom() {
    this.userService.inviteToTheRoom('doctor');
    this.isInvitationInProgress = true;

    setTimeout(() => {
      this.isInvitationInProgress = false;
    }, 3000);
  }

  ngAfterViewInit() {

    this.userService.setPage('videoChat');
    sessionStorage.wasInTheCall = true;

    if (this.streamingService.leftChat) {
      this.streamingService.leftChat = false;
      window.location.reload();
    }

    this.userService
      .observeUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        const currentDate: number = moment(user.now as any).valueOf();
        const meetingDate: number = moment(user.start as any).valueOf();
        const delayTime = parseInt((user.joinTime as any), 10);
        const diffTime: number = meetingDate - currentDate;  // For doctor's invitation button

        this.isInvitationNeeded = diffTime > 0 && (diffTime + delayTime) > 0;
        this.selfAvatar = user.avatar || avatarDefault;
        this.callerAvatar = user.callerAvatar || avatarDefault;
        this.isDoctor = user.role === 'doctor';
        this.role = user.role;

        if (this.isInvitationNeeded && !this.isDoctor) { // For patient
          this.router
            .navigate([this.userService.getRoomId(), this.userService.getToken()])
            .then(() => location.reload());
        }

        this.initializeTimer(user);
        this.cdr.detectChanges();
      });

    this.streamingService.videoSelf = this.videoSelf;
    this.streamingService.videoCaller = this.videoCaller;

    if (this.settingsService.isDeviceChanged || sessionStorage.camera) {
      this.streamingService.changeCamera(this.videoSelf);
    }
  }

  updateVolume = () => {
    this.videoCaller.nativeElement.volume = this.volume / 100;

    this.settingsService
      .updateSettings({
        ...this.settingsService.getSettings(),
        videoVolume: this.volume
      });
  }


  updateAudioMuteEnabled = async () => {
    const permissions = await this.streamingService.checkPermissions();

    if (permissions) {
      const success = this.streamingService.toggleAudio(this.streamingService.audioMuteEnabled);

      if (success) {
        this.settingsService
          .updateSettings({
            ...this.settingsService.getSettings(),
            audioMuteEnabled: !this.streamingService.audioMuteEnabled
          });
      }
    }
  }

  updateVideoMuteEnabled = async () => {
    const permissions = await this.streamingService.checkPermissions();
    if (permissions) {
      const success = this.streamingService.toggleVideo(this.videoMuteEnabled);

      if (success) {
        this.settingsService
          .updateSettings({
            ...this.settingsService.getSettings(),
            videoMuteEnabled: !this.videoMuteEnabled
          });
      }
    }
    this.streamingService.sendStuffP2P({ camera: !this.videoIsHidden() });
  }

  updateScreenShareEnabled = async () => {
    const isSafari = this.streamingService.isSafari();

    /**
     * Skip permissions check on Safari Desktop as it causes error
     * InvalidAccessError: getDisplayMedia must be called from a user gesture handler."
     * There could be issue for Safari if user denies camera access on first site visit.
     */

    const permissions = isSafari || await this.streamingService.checkPermissions();

    if (permissions) {
      if (!this.screenSharingEnabled) {
        this.streamingService.screenShare();
      } else {
        this.streamingService.stopScreenShare();
      }
    }
  }


  finishCall = () => {
    this.popupService.openDialog(
      this.translate.instant('popups.notification'),
      this.translate.instant('popups.finishCall'),
      this.translate.instant('popups.yes'),
      this.translate.instant('popups.no')
    ).subscribe(
      (result) => {
        if (result) {
          this.streamingService.disconnect();
          this.userService.logOut(this.role);
          this.userService.setPage('thanks');
        //  sessionStorage.clear();
          this.router.navigate(['/thanks']);
        }
      });
  }

  showVolume = () => {
    this.volumeVisible = true;
  }

  hideVolume = () => {
    this.volumeVisible = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.untilFinishedJoinTime.complete();
    this.userService.logOut(this.role);
    this.streamingService.leftChat = true;
  }

  private initializeTimer(user: User) {

    if (user.role !== 'doctor') {
      return;
    }

    this.untilFinishedJoinTime.next();
    // We use 'moment' instead of Date.parse, because Date.parse returns 'NaN' in Safari
    const currentDate = moment(user.now).valueOf();
    const meetingDate = moment(user.start).valueOf();



    const diffTime = meetingDate - currentDate;

    interval(1000)
      .pipe(
        takeUntil(this.unsubscribe$),
        takeUntil(this.untilFinishedJoinTime)
      )
      .subscribe(times => {
        times *= 1000;

        if (diffTime - times <= 0) {
          this.isInvitationNeeded = false;
          this.untilFinishedJoinTime.next();
          this.cdr.detectChanges();
        }
      });
  }
}
