import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultilanguageService {
  public currentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>('de');

  constructor(public translate: TranslateService) {
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('de');
    const localStorageLanguage: string = localStorage.getItem('language');
    if (!localStorageLanguage || localStorageLanguage === '') {
      this.setDefaultLanguage();
    } else {
      this.setLanguage(localStorageLanguage);
    }
  }

  private deCanvasToolBox: {} = {
    'pencil-icon': 'Stift',
    'marker-icon': 'Markierstift',
    'eraser-icon': 'Zeichnungen Löschen',
    'text-icon': 'Text Schreiben',
    'image-icon': 'Bild Hinzufügen',
    'pdf-icon': 'PDF Hinzufügen',
    'drag-last-path': 'Letzte Kontur Ziehen/Verschiebenh',
    'drag-all-paths': 'Alle Konturen Ziehen/Verschieben',
    'line': 'Linien Zeichnen',
    'arrow': 'Pfeile Zeichnen',
    'zoom-up': 'Hineinzoomen',
    'zoom-down': 'Rauszoomen',
    'arc': 'Bogen',
    'rectangle': 'Rechteck',
    'quadratic-curve': 'Quadratische Kurve',
    'bezier-curve': 'Bezier-Kurve',
    'undo': 'Rückgängig Machen: Letzte Formen Entfernen',
    'line-width': 'Linienbreite Einstellen',
    'colors': 'Vordergrund- und Hintergrundfarben Einstellen',
    'additional': 'Zusätzliche Optionen',
    'image-save': 'Als Bild Speichern\nEs wird nur der Bereich gespeichert, zu dem Sie scrollen können',
    'line-width-done': 'Schließen',
    'colors-done': 'Schließen'
  };

  private enCanvasToolBox: {} = {
    'pencil-icon': 'Pencil',
    'marker-icon': 'Marker',
    'eraser-icon': 'Erase Drawings',
    'text-icon': 'Write Text',
    'image-icon': 'Add Image',
    'pdf-icon': 'Add PDF',
    'drag-last-path': 'Drag/Move Last Path',
    'drag-all-paths': 'Drag/Move All Paths',
    'line': 'Draw Lines',
    'arrow': 'Draw Arrows',
    'zoom-up': 'Zoom-In',
    'zoom-down': 'Zoom-Out',
    'arc': 'Arc',
    'rectangle': 'Rectangle',
    'quadratic-curve': 'Quadratic Curve',
    'bezier-curve': 'Bezier Curve',
    'undo': 'Undo: Remove Recent Shapes',
    'line-width': 'Set Line Width',
    'colors': 'Set Foreground and Background Colors',
    'additional': 'Extra Options',
    'image-save': 'Save as Image\nOnly area you can scroll to will be saved',
    'line-width-done': 'Close',
    'colors-done': 'Close'
  };

  private defaultLanguage = 'de';

  private currentLanguageString: string;

  private getToolTips(language: string): {} {
    return language === 'en' ? this.enCanvasToolBox : this.deCanvasToolBox;
  }

  setDefaultLanguage() {
    this.defaultLanguage = 'de';
    this.setLanguage('de');
  }

  getCurrentLanguageString(): string {
    return this.currentLanguageString ? this.currentLanguageString : this.defaultLanguage;
  }

  getLanguage() {
    return this.currentLanguage.asObservable();
  }

  setLanguage(language: string) {
    this.translate.use(language);
    this.currentLanguage.next(language);
    this.currentLanguageString = language;
    const localStorageLanguage: string = localStorage.getItem('language');
    if (!localStorageLanguage || localStorageLanguage === '' || localStorageLanguage !== language) {
      localStorage.setItem('language', language);
    }
  }

  public translateToolTips(language: string): void {
    /** Accessing the canvas inside a sketch component widget placed in iframe
    // The widget is third-party and it is the way it supposed to work from the box
    // (wrapping whole thng in iframe and appending it to some div)
    // Tooltips are the elements of that widget - that's why we have to access it directly to translate them*/
    const canvasDocument: Document = document.getElementsByTagName('iframe')[0].contentWindow.document;
    const toolTips: {} = this.getToolTips(language);

    Object.keys(toolTips).forEach((key) => {
      if (canvasDocument.getElementById(key)) {
        if (canvasDocument.getElementById(key).title) {
          canvasDocument.getElementById(key).title = toolTips[key];
        } else {
          canvasDocument.getElementById(key).innerText = toolTips[key];
        }
      }
    });
  }
}
