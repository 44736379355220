import { Component, NgZone, OnDestroy } from '@angular/core';
import { switchMap, take, tap } from 'rxjs/operators';
import { HeaderAbstractComponent } from '../abstracts/header.abstract.component';
import { Pages, UserService } from '../../../services/user.service';
import { MultilanguageService } from '../../../services/multilanguage.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../../services/popup.service';
import { Router } from '@angular/router';
import { VideoStreamingService } from '../../../services/video-streaming.service';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})

export class HeaderMobileComponent
  extends HeaderAbstractComponent
  implements OnDestroy {

  displayMenu = false;

  constructor(protected userService: UserService,
              public multiLanguageService: MultilanguageService,
              public translate: TranslateService,
              public popupService: PopupService,
              protected router: Router,
              protected _ngZone: NgZone,
              protected streamingService: VideoStreamingService,
              public settingsService: SettingsService
  ) {
    super(userService, multiLanguageService, translate, popupService, router, _ngZone, streamingService, settingsService);
  }

  toggleActive(pageName: Pages) {
    if (this.settingsService.isOpened) {
      this.closeSettings()
        .subscribe(() => {
          this.page = pageName;
          this.streamingService.activeArea = this.page;
          this.settingsService.isOpened = false;
        });
    } else {
      this.page = pageName;
      this.streamingService.activeArea = this.page;
    }
  }

  toggleMenu() {
    this.displayMenu = !this.displayMenu;
  }

  closeMenu() {
    this.displayMenu = false;
  }

  chooseRoom(page: Pages) {
    super.chooseRoom(page);
    this.closeMenu();
  }

  deactivateSketch() {
    super.deactivateSketch();
    this.closeMenu();
  }

  getVisibleUserInfo = () => {
    return this.page !== 'thanks';
  }
}
