import { Component, NgZone } from '@angular/core';
import { HeaderAbstractComponent } from '../abstracts/header.abstract.component';
import { UserService } from '../../../services/user.service';
import { MultilanguageService } from '../../../services/multilanguage.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../../services/popup.service';
import { Router } from '@angular/router';
import { VideoStreamingService } from '../../../services/video-streaming.service';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss']
})

export class HeaderDesktopComponent
  extends HeaderAbstractComponent {
  constructor(protected userService: UserService,
              public multiLanguageService: MultilanguageService,
              public translate: TranslateService,
              public popupService: PopupService,
              protected router: Router,
              protected _ngZone: NgZone,
              protected streamingService: VideoStreamingService,
              public settingsService: SettingsService
  ) {
    super(userService, multiLanguageService, translate, popupService, router, _ngZone, streamingService, settingsService);
  }
}
