import { Component, OnDestroy, AfterViewInit, ChangeDetectorRef, NgZone, Output,EventEmitter } from '@angular/core';
import { VideoStreamingService } from '../../../services/video-streaming.service';
import { SettingsService } from '../../../services/settings.service';
import { UserService } from '../../../services/user.service';
import { PopupService } from '../../../services/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { VideochatAbstract } from '../abstracts/videochat.abstract.component';

@Component({
  selector: 'app-videochat-desktop',
  templateUrl: './videochat-desktop.component.html',
  styleUrls: ['./videochat-desktop.component.scss']
})
export class VideochatDesktopComponent
  extends VideochatAbstract
  implements OnDestroy, AfterViewInit {
     actionBarVisible=true;

      @Output() toggleActionBar = new EventEmitter<string>();

  constructor(cdr: ChangeDetectorRef,
              streamingService: VideoStreamingService,
              settingsService: SettingsService,
              userService: UserService,
              popupService: PopupService,
              translate: TranslateService,
              deviceDetectorService: DeviceDetectorService,
              router: Router,
              ngZone: NgZone) {
    super(cdr, streamingService, settingsService, userService, popupService, translate, deviceDetectorService, router, ngZone);
  }

  toggleActionBarVisibility(){
this.actionBarVisible=!this.actionBarVisible;
    this.toggleActionBar.emit();
  }
}
