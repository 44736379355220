import { Component, OnInit, ViewChild,Output,EventEmitter } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { VideoStreamingService } from '../../services/video-streaming.service';
import { UnsubscribeAbstract } from '../abstracts/unsubscribe.abstract';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-actions-bar',
  templateUrl: './actions-bar.component.html',
  styleUrls: ['./actions-bar.component.css']
})
export class ActionsBarComponent
  extends UnsubscribeAbstract
  implements OnInit {
  isChat = false;
  isDoc = false;
  isNotes = false;
  chat_access=true;
  notes_access=true;
  documents_access=true;
  sketch_access=true;


  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
  private currentTab = 0;

  constructor(public streamingService: VideoStreamingService,public userService: UserService) {
    super();
  }
  ngOnInit() {
    this.userService.observeUser()
      .subscribe(data => {
        if(data.chat_access!=null && data.sketch_access!=null && data.documents_access!=null && data.notes_access!=null)
        {
          this.chat_access=data.chat_access;
          this.sketch_access=data.sketch_access;
          this.documents_access=data.documents_access;
          this.notes_access=data.notes_access;
        }
      });

    this.streamingService
      .observeSketchState()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        if (!state && this.tabGroup && this.tabGroup.selectedIndex === 3) {
          console.log('returning to chat from sketch', this.isChat, 'sketch state', state);
          this.isChat = true;
          this.tabGroup.selectedIndex = 0;
        }
      });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    console.log("tab",tabChangeEvent);
    const tab = this.currentTab;
    if (tabChangeEvent.tab.textLabel === "Skizzen") {
      this.streamingService
        .checkPermissions()
        .then(res => {
            if (!res) {
              this.tabGroup.selectedIndex = tab;
            } else {
              this.streamingService.sketchIsActive.next(tabChangeEvent.tab.textLabel === "Skizzen");
            }
          }
        );
    } else {
      this.streamingService.sketchIsActive.next(false);
      this.isChat = tabChangeEvent.tab.textLabel === "Chat";
      this.isNotes = tabChangeEvent.tab.textLabel === "Notizen";
      this.isDoc = tabChangeEvent.tab.textLabel === "Dokumente";
    }
    this.currentTab = this.tabGroup.selectedIndex;
  }
}
