import { Injectable } from '@angular/core';
import { PopupComponent } from '../components/popup/popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Injectable()
export class PopupService {
  constructor(public dialog: MatDialog,
              public translate: TranslateService) {
  }

  openErrorNotification(content: any, link?: string, additionalContent?: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      content: content.toString(),
      isNotification: true,
      rejectBtn:  this.translate.instant('popups.close'),
      link: link || '',
      additionalContent: additionalContent || ''
    };

    // Check if we already have a dialog with the same message opened
    if (this.dialog.openDialogs && this.dialog.openDialogs.find((instance) => {
      return instance.componentInstance.content === dialogConfig.data.content;
    })) {
      return;
    }

    this.dialog.open(PopupComponent, dialogConfig);
  }

  async openErrorDialog(content: any, additionalContent?: string) {
    // Check if we already have a dialog with the same message opened
    if (this.dialog.openDialogs && this.dialog.openDialogs.find((instance) => {
      return instance.componentInstance.content === content.toString();
    })) {
      return;
    }
    return this.openDialog('', content, null, this.translate.instant('popups.close'), true)
      .toPromise();
  }

  openDialog(title: string, content: string, acceptBtn?: string, rejectBtn?: any, isNotification?: boolean) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      title,
      content: content.toString(),
      isNotification: isNotification ? isNotification : false,
      acceptBtn: acceptBtn ? acceptBtn : null,
      rejectBtn: rejectBtn ? rejectBtn : this.translate.instant('popups.cancel')
    };

    return this.dialog
      .open(PopupComponent, dialogConfig)
      .afterClosed()
      .pipe(first());
  }
}
