import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanks-page',
  templateUrl: './thanks-page.component.html',
  styleUrls: ['./thanks-page.component.css']
})
export class ThanksPageComponent implements OnInit {

  public colorSecondary;

  constructor() { }

  ngOnInit() {
    this.colorSecondary=sessionStorage.colorSecondary;
    sessionStorage.removeItem('wasInTheCall');
      sessionStorage.clear();

  }
}
