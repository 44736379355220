import { Injectable, NgZone } from '@angular/core';
import { PopupComponent } from '../components/popup/popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class MobileDebugService {
  private i = 0;
  private logs = new BehaviorSubject([]);
  constructor(public ngZone: NgZone) {
  }

  add(...args) {
    const logs = this.logs.getValue();
    args.forEach(arg => {
      logs.push({
        message: arg,
        time: Date.now().toString(),
        id: this.i++
      });
    });

    this.logs.next(logs);
  }

  get logs$() {
    return this.logs.asObservable();
  }
}
