import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent {

  acceptBtn: string;
  additionalContent: string;
  content: string;
  isNotification: boolean;
  link: string;
  rejectBtn: string;
  title: string;

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService) {

    this.content = data.content;
    this.title = data.title ? data.title : this.translate.instant('popups.notification');
    this.acceptBtn = data.acceptBtn ? data.acceptBtn : this.translate.instant('popups.accept');
    this.rejectBtn = data.rejectBtn;
    this.isNotification = data.isNotification;
    this.link = data.link;
    this.additionalContent = data.additionalContent;
    this.dialogRef.disableClose = true;
  }
}
