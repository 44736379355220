import {
  Component,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  OnChanges,
  Input
} from '@angular/core';
import { VideoStreamingService } from '../../services/video-streaming.service';
import { takeUntil } from 'rxjs/internal/operators';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeAbstract } from '../abstracts/unsubscribe.abstract';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})

export class DocumentsComponent
  extends UnsubscribeAbstract
  implements AfterViewInit, OnDestroy, OnChanges {
  @Input() onDoc: boolean;
  @ViewChild('docs', {static: false}) docs: any;
  @ViewChild('documentsBox', {static: false}) documentsBox: ElementRef;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  public documents: Array<any> = [];
  public chosenDocument = {
    name: '',
    size: 0
  };
  public isDoctor = false;
  public enableSending = true;
  private labelEnable = false;

  constructor(private streamingService: VideoStreamingService,
              private popupService: PopupService,
              private userService: UserService,
              public translate: TranslateService,
              private cdr: ChangeDetectorRef) {
    super();

    this.streamingService
      .getReceivedFilesStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(file => {
        const currentDocument = this.documents.length ? this.documents[this.documents.length - 1] : null;
        switch (file.status) {
          case 'started':
            break;
          case 'started_file':
            this.documents.push({
              name: file.name,
              size: file.size,
              position: 0,
              status: this.translate.instant('documents.started'),
              showProgress: false,
              date: new Date()
          });
            break;
          case 'progress':
            if (currentDocument) {
              currentDocument.status = this.translate.instant('documents.progress');
              currentDocument.position = file.received;
              currentDocument.size = file.size;
              currentDocument.showProgress = true;
            }
            break;
          case 'done':
            if (currentDocument) {
              currentDocument.status = this.translate.instant('documents.success');
              currentDocument.showProgress = false;
            }
            break;

          default:
            console.log('Unknown status');
        }
        this.cdr.detectChanges();
        setTimeout(() => this.documentsBox.nativeElement.scrollTop = this.documentsBox.nativeElement.scrollHeight, 10);
      });

    this.streamingService
      .getSentFilesStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(file => {
        const currentDocument = this.documents.length ? this.documents[this.documents.length - 1] : null;
        switch (file.status) {
          case 'waiting':
            this.documents.push({
              status: this.translate.instant('documents.waiting'),
              name: this.chosenDocument.name,
              size: this.chosenDocument.size,
              position: 0,
              showProgress: false,
              date: new Date()
            });
            this.enableSending = false;
            break;
          case 'started':
            if (currentDocument) {
              currentDocument.status = this.translate.instant('documents.started');
              currentDocument.showProgress = true;
            }
            this.enableSending = true;
            break;
          case 'working':
            if (currentDocument) {
              currentDocument.status = this.translate.instant('documents.progress');
              currentDocument.position = file.position;
              currentDocument.showProgress = true;
            }
            break;
          case 'rejected':
            if (currentDocument) {
              currentDocument.status = this.translate.instant('documents.cancelled');
              currentDocument.showProgress = false;
            }
            this.enableSending = true;
            (document.querySelector('#fileInput') as HTMLInputElement).value = '';
            break;
          case 'done':
            if (currentDocument) {
              currentDocument.status = this.translate.instant('documents.success');
              currentDocument.position = currentDocument.size;
              currentDocument.showProgress = false;
            }
            (document.querySelector('#fileInput') as HTMLInputElement).value = '';
            break;
          default:
            console.log('Unknown status');
        }
        this.cdr.detectChanges();
        setTimeout(() => this.documentsBox.nativeElement.scrollTop = this.documentsBox.nativeElement.scrollHeight, 10);
      });

    this.userService
      .observeUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => this.isDoctor = user.role === 'doctor');
  }

  ngAfterViewInit() {
    this.streamingService.buildDragNDropRegion(this.docs);
  }

  ngOnChanges() {
    setTimeout(() => this.documentsBox.nativeElement.scrollTop = this.documentsBox.nativeElement.scrollHeight, 10);
  }

  checkConnection(event) {
    if (!this.labelEnable) {
      event.preventDefault();

      this.streamingService
        .checkPermissions()
        .then(res => {
          if (!res) {
            event.returnValue = false;
          } else {
            this.labelEnable = true;
            this.fileInput.nativeElement.click();
          }
        });
    } else {
      const isConnected = this.streamingService.getPartnerId();
      if (!isConnected) {
        event.preventDefault();
        this.popupService.openErrorNotification(this.translate.instant('popups.fileSendingError'));
      }
      if (!this.enableSending) {
        event.preventDefault();
        this.popupService.openErrorNotification(this.translate.instant('popups.fileWaitingMessage'));
      }
    }
  }

  uploadDocument(event) {
    if (this.fileInput.nativeElement.value.length > 0) {
      const files = event.target.files;
      this.chosenDocument.name = files[0].name;
      this.chosenDocument.size = files[0].size;
      this.streamingService.fileHandler(files);
    }
  }
}
