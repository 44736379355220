import { Component, OnInit } from '@angular/core';
import { MobileDebugService } from './services/mobile-debug.service';
import { fromEvent, Observable } from 'rxjs';
import { bufferWhen, debounceTime, filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent
  implements OnInit {

  logs$: Observable<any[]>;
  areEnabledLogs = false;

  constructor(private logService: MobileDebugService) {
    this.logs$ = logService.logs$;
  }

  ngOnInit() {
    document.body.addEventListener('dragover', (e) => {
      e.preventDefault();
      e.stopPropagation();
    }, false);
    document.body.addEventListener('drop', (e) => {
      e.preventDefault();
      e.stopPropagation();
    }, false);

    if (!environment.production) {
      const click$ = fromEvent(document.body, 'touchstart');
      click$
        .pipe(
          bufferWhen(() => click$.pipe(debounceTime(250))),
          map(list => list.length),
          filter(length => length >= 3),
        )
        .subscribe(() => this.areEnabledLogs = !this.areEnabledLogs);
    }
  }
}
