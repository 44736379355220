import {Component, OnChanges, ViewChild, ElementRef, Input} from '@angular/core';
import { UserService } from '../../services/user.service';
import { takeUntil } from 'rxjs/operators';
import {DeviceDetectorService} from 'ngx-device-detector';
import { UnsubscribeAbstract } from '../abstracts/unsubscribe.abstract';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent
  extends UnsubscribeAbstract
  implements OnChanges {

  @Input() onNotes: boolean;
  @ViewChild('notesBox', {static: false}) notesBox: ElementRef;

  private noteId = 0;
  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  };

  public userName: string;
  public fileName: string;
  public currentNote: string;
  public currentHeadline: string;
  public notes: any = [];

  isMobile: boolean;

  constructor(private userService: UserService,
              protected deviceDetectorService: DeviceDetectorService) {
    super();
    this.isMobile = this.deviceDetectorService.isMobile();

    this.userService
      .observeUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.userName = user.firstName + ' ' + user.lastName;
      });
  }

  ngOnChanges() {
    setTimeout(() => this.notesBox.nativeElement.scrollTop = this.notesBox.nativeElement.scrollHeight, 5);
  }

  // Add note to array
  saveNote() {
    if (this.currentNote === ' ' || this.currentNote === undefined) {
      return;
    }

    const note = {
      id: ++this.noteId,
      date: new Date(),
      headline: this.currentHeadline,
      text: this.currentNote.replace('\\n', '<br />')
    };

    this.notes.push(note);

    setTimeout(() => this.notesBox.nativeElement.scrollTop = this.notesBox.nativeElement.scrollHeight, 5);

    this.currentHeadline = '';
    this.currentNote = '';
  }

  // Save note as .txt
  dynamicDownloadTxt(note) {
    if (!note.headline) {
      note.headline = 'not specified';
    }
    this.fileName = this.userName + ':' + note.headline + '.txt';
    this.dynamicDownloadByHtmlTag({
      fileName: this.fileName,
      text: note.text
    });
  }

  delete(selectedNote) {
    this.notes = this.notes.filter(note => note.id !== selectedNote.id);
  }

  private dynamicDownloadByHtmlTag(arg: {fileName: string, text: string}) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    const event = new MouseEvent('click');
    element.dispatchEvent(event);
  }
}
