import { NgZone, OnDestroy } from '@angular/core';
import { Role } from '../../../models/user';
import { Pages, UserService } from '../../../services/user.service';
import { MultilanguageService } from '../../../services/multilanguage.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../../services/popup.service';
import { Router } from '@angular/router';
import { VideoStreamingService } from '../../../services/video-streaming.service';
import { SettingsService } from '../../../services/settings.service';
import { first, switchMap, takeUntil, tap } from 'rxjs/internal/operators';
import { UnsubscribeAbstract } from '../../abstracts/unsubscribe.abstract';
import { take } from 'rxjs/operators';
import { iif, of } from 'rxjs';

export abstract class HeaderAbstractComponent
  extends UnsubscribeAbstract
  implements OnDestroy {

  public name: string;
  public avatar: string;
  public doctorStart;
  public roomId: string;
  public token: string;
  public selectedId: string;
  private role: Role;
  public page: string;
  public logoUrl: string;

  protected constructor(protected userService: UserService,
              public multiLanguageService: MultilanguageService,
              public translate: TranslateService,
              public popupService: PopupService,
              protected router: Router,
              protected _ngZone: NgZone,
              protected streamingService: VideoStreamingService,
              protected settingsService: SettingsService
  ) {
    super();

    this.userService
      .observeUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this._ngZone.run(() => {
          this.name = user.firstName + ' ' + user.lastName;
          this.avatar = user.avatar;
          this.doctorStart = user.doctor.loggedIn;
          this.role = user.role;
          this.logoUrl=user.logoUrl;
          sessionStorage.setItem('colorPrimary',user.colorPrimary);
          sessionStorage.setItem('colorSecondary',user.colorSecondary);
        });
      });

    this.roomId = this.userService.getRoomId();
    this.token = this.userService.getToken();

    this.multiLanguageService
      .getLanguage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((language) => {
        this.selectedId = language;
      });

    this.userService
      .observePage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(page => {
        this._ngZone.run(() => {
          this.page = page;
        });
      });
  }

  toggleSettings() {
    if (this.settingsService.isOpened) {
      this.closeSettings()
        .pipe(first())
        .subscribe(() => {
          this.settingsService.isOpened = false;
        });
    } else {
      this.settingsService.isOpened = true;
    }
  }

  closeSettings() {
    return iif(
      () => JSON.stringify(this.settingsService.getSettings()) === JSON.stringify(this.settingsService.getSavedSettings()),
      of(true),
      this.popupService.openDialog(
        null,
        this.translate.instant('settings.save-confirm'),
        this.translate.instant('popups.yes'),
        this.translate.instant('popups.no')
      )
    )
      .pipe(
        first(),
        switchMap(result => iif(() => result, this.settingsService.getStream(), this.settingsService.restoreSettings())),
        take(1),
        tap(stream => {
          if (stream) {
            this.streamingService.settingsChangesStream(stream.clone());
          } else {
            console.error('there is no steam');
          }
        })
      );
  }

  closeSettingsBySaving() {
    this.settingsService
      .getStream()
      .pipe(take(1))
      .subscribe(stream => {
        if (stream) {
          this.streamingService.settingsChangesStream(stream.clone());
        } else {
          console.error('there is no steam');
        }
        this.settingsService.isOpened = false;
      });
  }

  deactivateSketch() {
    this.streamingService.sketchIsActive.next(false);
  }

  chooseRoom(page: Pages) {
    this.userService.setPage(page);
  }

  getVisibleUserInfo = () => {
    return this.page !== 'thanks' && this.page !== 'settings';
  }

  logOut() {
    this.popupService
      .openDialog(
        this.translate.instant('popups.confirmExit'),
        this.translate.instant('popups.exitText'),
        this.translate.instant('popups.yes'),
        this.translate.instant('popups.no')
      )
      .subscribe(result => {
          if (result) {
            this.streamingService.disconnect();
            this.userService.logOut(this.role);
            this.userService.setPage('thanks');
          //  sessionStorage.clear();
            this.router.navigate(['/thanks']);
          }
        }
      );
  }

  changeLanguage(value: string) {
    try {
      this.multiLanguageService.translateToolTips(value);
    } catch (error) {
      console.log('Changing language outside of sketch, tooltips not yet created.\nPrevented error: ', error);
    }

    if (value === 'en') {
      this.selectedId = 'en';
    } else if (value === 'de') {
      this.selectedId = 'de';
    }
    this.multiLanguageService.setLanguage(value);
  }
}
