import {
  Component,
  ElementRef,
  Input,
  NgZone, OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { VideoStreamingService } from '../../services/video-streaming.service';
import { takeUntil } from 'rxjs/internal/operators';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { PopupService } from '../../services/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeAbstract } from '../abstracts/unsubscribe.abstract';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent
  extends UnsubscribeAbstract
  implements OnChanges {

  @Input() onChat: boolean;
  @ViewChild('sendM', { static: false }) sendM: any;
  @ViewChild('chatBox', { static: false }) chatBox: ElementRef;

  public usersData;
  public role: string;
  public messages = [];
  public message: string;
  public currentMessageData: any;
  public attachment: any;
  public opponentConnected: boolean;
  public src: any;
  public imageCounter = 0;

  constructor(private _ngZone: NgZone,
              private streamingService: VideoStreamingService,
              private userService: UserService,
              private popupService: PopupService,
              public translate: TranslateService) {
    super();

    const roomData: any = JSON.parse(localStorage.getItem(this.userService.roomId));
    this.messages = roomData && roomData.chat ? roomData.chat : [];

    this.streamingService
      .getMessages()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(messageData => {  // The check is to ensure we can not send absolutely empty message, but still can send image
        // And to make sure we don't mistake messages from the sketch canvas for messages from the chat
        // update messages, if partner's list of messages is not empty
        if (messageData.message.chat) {
          const chat: any[] = JSON.parse(messageData.message.chat);
          if (chat.length > this.messages.length) { // Updating only if partner has more recent snapshot of the chat
            this.messages = chat;
            this.updateLocalStorage();
          }
        } else {
          if ((messageData.message.src || (messageData.message.text && messageData.message.text.length > 0)
            && !messageData.message.canvasDesignerSyncData && !messageData.message.canvasWidth && !messageData.message.canvasHeight)) {
            this._ngZone.run(() => {
              this.messages.push(messageData.message);
              if (messageData.sender === 'me') {
                this.message = '';
              }
              // Saving chat snapshot to local storage
              this.updateLocalStorage();
            });
          }
        }
        // set timeout necessary for the DOM has time to insert an element before applying scrolltop
        setTimeout(() => {
          this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
        }, 300);
      });

    this.streamingService
      .observePartnerId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(id => {
        this.opponentConnected = !!id;
        // send current messages(if they exist), if partner connected
        if (this.opponentConnected && this.messages.length) {
          this.streamingService.updateMessages(JSON.stringify(this.messages));
        }
      });

    this.userService
      .observeUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.role = user.role;
        this.usersData = user;
      });
  }

  /** The goal of using local storage is to let participants revisit room shortly after completing the call.
   * in order to see chat history, sketch, etc.
   * Saving is done for each room separately.
   * Can also help in rare test cases, when two participants have internet connection problems almost simultaneously:
   * - their internet provider is having problems;
   * - they both have bad mobile internet, which often loses connection; */

  private updateLocalStorage() { // To update local storage for room without erasing canvas data in it
    const roomData: any = JSON.parse(localStorage.getItem(this.userService.roomId)) || {};
    roomData['chat'] = this.messages;

    localStorage.setItem(this.userService.roomId, JSON.stringify(roomData));
  }

  ngOnChanges() {
    setTimeout(() => this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight, 10);
  }

  isLink = (message) => {
    return message !== this.linkify(message);
  }

  linkify(inputText) {
    // URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    let replacedText = inputText.replace(replacePattern1, '<a href="$1" style="color:#3ec3c3" target="_blank">$1</a>');

    // URLs starting with www. (without // before it, or it'd re-link the ones done above)
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" style="color:#3ec3c3" target="_blank">$2</a>');

    // Change email addresses to mailto:: links
    const replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" style="color:#3ec3c3">$1</a>');

    return replacedText;
  }

  checkConnection(event) {
    if (!this.opponentConnected) {
      event.preventDefault();
      this.popupService.openErrorNotification(this.translate.instant('popups.fileSendingError'));
    }
  }

  changeListener($event) {
    this.sendImg($event.target);
  }

  sendImg(inputValue: any) {
    const file = inputValue.files[0];
    const myReader = new FileReader();

    myReader.onloadend = () => {
      this.src = myReader.result;

      this.currentMessageData = {
        avatar: this.usersData.avatar,
        date: new Date(),
        src: this.src,
        roleDoctor: this.usersData.role === 'doctor'
      };

      this._ngZone.run(() => {
        this.streamingService.sendStuffP2P(this.currentMessageData);
      });

      (document.querySelector('#file_input_file') as HTMLInputElement).value = '';
    };

    myReader.readAsDataURL(file);
  }

  // convert base64 to blob and save
  public saveImg(dataurl) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    this.imageCounter = ++this.imageCounter;
    const filename = 'chat-image' + this.imageCounter;
    const type = mime.substr(mime.lastIndexOf('/') + 1);
    this.streamingService.blobAcceptor('', new File([u8arr], filename, { type: mime }), 'chat-image' + this.imageCounter + '.' + type);
  }

  insertNewLine(e) {
    if (e.keyCode === 13 && e.shiftKey) { // keyCode 13 - Enter
      return;
    } else if (e.keyCode === 13) {
      e.preventDefault();
      this.sendData(e);
    }
  }

  // send message through p2p
  sendData(event) {
    event.preventDefault();

    this.streamingService
      .checkPermissions()
      .then(() => {
        if (!this.opponentConnected) {
          this.popupService.openErrorNotification(this.translate.instant('popups.fileSendingError'));
        } else {

          this.currentMessageData = {
            avatar: this.usersData.avatar,
            date: new Date(),
            text: this.message,
            roleDoctor: this.usersData.role === 'doctor'
          };

          this._ngZone.run(() => {
            this.streamingService.sendStuffP2P(this.currentMessageData);
          });

        }
      });
  }
}

