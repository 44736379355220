import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {
  isDesktop: boolean;

  constructor(protected deviceDetectorService: DeviceDetectorService) {
    this.isDesktop = this.deviceDetectorService.isDesktop();
  }
}
