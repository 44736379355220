import { Component } from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent {
  isDesktop: boolean;

  constructor(protected deviceDetectorService: DeviceDetectorService) {
    this.isDesktop = this.deviceDetectorService.isDesktop();
  }
}
