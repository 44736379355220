import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VideoStreamingService } from '../../services/video-streaming.service';
import { UserService } from '../../services/user.service';
import { filter, takeUntil } from 'rxjs/operators';
import { PopupService } from '../../services/popup.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UnsubscribeAbstract } from '../abstracts/unsubscribe.abstract';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent
  extends UnsubscribeAbstract
  implements OnInit {

  @ViewChild('sketchBoard', { static: false }) board: ElementRef;

  private opponentConnected = false;
  private hacker = false;
  private roomId: string;
  private token: string;
  isDesktop: boolean;
  isActionToggleBarVisible=true;

  constructor(public userService: UserService,
              private _ngZone: NgZone,
              private router: Router,
              private popupService: PopupService,
              private streamingService: VideoStreamingService,
              public translate: TranslateService,
              protected deviceDetectorService: DeviceDetectorService) {
    super();

    this.isDesktop = this.deviceDetectorService.isDesktop();

    this.roomId = this.userService.getRoomId();
    this.token = this.userService.getToken();

    this.streamingService
      .getConnectionStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(status => {
        this.opponentConnected = status;
        if (this.opponentConnected === false && this.hacker) {
          this._ngZone.run(() => {
            this.popupService.openErrorNotification(this.translate.instant('popups.interlocutor'));
          });
        }
      });

    this.streamingService
      .observeThirdConnection()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(connections => {
        if (!connections) {
          this._ngZone.run(() => {
            this.popupService.openErrorNotification(this.translate.instant('popups.accessDenied'));
            this.streamingService.disconnect();
            this.router.navigateByUrl(`/${this.roomId}/${this.token}`)
              .catch(error => {
                console.log(error);
              });
          });
        }
      });
  }
  toggleActionBarVisibility() {
    this.isActionToggleBarVisible=!this.isActionToggleBarVisible;

}

  async ngOnInit() {

    this.userService.setPage('videoChat');


    this.userService
      .observeRole()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(v => !!v)
      )
      .subscribe(role => {
        this.userService.logIn(role);
      });

    const cameraId = sessionStorage.camera || null;
    const microphoneId = sessionStorage.microphone || null;

    const videoDevice = await navigator.mediaDevices.enumerateDevices().then(devices => {
      return devices.find(device => device.kind === 'videoinput');
    });

    if (navigator.userAgent.includes('Chrome') && navigator['permissions'] && typeof navigator['permissions'].query === 'function') {
      
      Promise.all([
        navigator['permissions'].query({ name: 'camera', deviceId: cameraId }),
        navigator['permissions'].query({ name: 'microphone', deviceId: microphoneId }),
      ])
        .then(([{ state: videoState }, { state: audioState }]) => {
          if (videoState === 'denied' || audioState === 'denied') {
            console.log('denied');
            setTimeout(() => {
              this.popupService.openErrorNotification(this.translate.instant('popups.deniedPermission'));
            }, 0);
          } else {
            if (videoState === 'prompt' || audioState === 'prompt') {
              console.log('prompt');

              setTimeout(() => {
                this.popupService.openErrorNotification(this.translate.instant('popups.promptPermission'));
              }, 0);
              
            }
          }

          this.streamingService.connect(!!videoDevice && videoState !== 'denied', audioState !== 'denied');

        })
        .catch(error => {
          this.streamingService.connect(false, false);
        });
    } else {
      const constraints = {
        ...videoDevice && {
          video: {deviceId: cameraId}
        },
        audio: {deviceId: microphoneId}
      };
      // Firefox and Safari
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          if (stream) {
            this.streamingService.connect(!!videoDevice && !!stream.getVideoTracks()[0], !!stream.getAudioTracks()[0]);
          }
        })
        .catch(error => {
          console.log('navigator.mediaDevices.getUserMedia error ', error);
          this.streamingService.connect(false, false);
          if (error.name === 'NotAllowedError') {
            setTimeout(() => {
              this.popupService.openErrorNotification(this.translate.instant('popups.deniedPermissionFF'));
            }, 0);
          }
        });

    }

   }

}
