import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { WaitingRoomAbstractComponent } from '../abstracts/waiting-room.abstract.component';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../../services/popup.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileDebugService } from '../../../services/mobile-debug.service';

@Component({
  selector: 'app-waiting-room-mobile',
  templateUrl: './waiting-room-mobile.component.html',
  styleUrls: ['./waiting-room-mobile.component.scss']
})

export class WaitingRoomMobileComponent
  extends WaitingRoomAbstractComponent {
  constructor(userService: UserService,
              router: Router,
              translate: TranslateService,
              cdr: ChangeDetectorRef,
              popupService: PopupService,
              deviceDetectorService: DeviceDetectorService,
              ngZone: NgZone,
              logsService: MobileDebugService
  ) {
    super(userService, router, translate, cdr, popupService, deviceDetectorService, ngZone, logsService);
  }
}
