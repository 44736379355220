import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators';
import { VideoStreamingService } from '../../services/video-streaming.service';
import { MultilanguageService } from '../../services/multilanguage.service';
import { UserService } from '../../services/user.service';
import { UnsubscribeAbstract } from '../abstracts/unsubscribe.abstract';

declare const CanvasDesigner: any;

@Component({
  selector: 'app-sketch-board',
  templateUrl: './sketch-board.component.html',
  styleUrls: ['./sketch-board.component.css']
})
export class SketchBoardComponent
  extends UnsubscribeAbstract
  implements OnInit, OnDestroy, AfterViewInit {

  constructor(private streamingService: VideoStreamingService,
              private multiLanguageService: MultilanguageService,
              private userService: UserService) {
    super();
  }

  private canvasDesigner: any;
  private canvasResized = false;
  private canvasDesignerSyncData: any = {
    points: [],
    startIndex: 0
  };

  ngOnInit() {
    this.canvasDesigner = new CanvasDesigner();
    this.canvasDesigner.widgetHtmlURL = '../../../assets/canvas-designer/widget.html';
    this.canvasDesigner.widgetJsURL = '../../../assets/canvas-designer/widget.js';

    this.canvasDesigner.setTools({
      pencil: true,
      marker: false,
      eraser: true,
      text: true,
      image: false,
      line: true,
      arrow: true,
      dragSimple: true,
      dragMultiple: true,
      arc: true,
      rectangle: true,
      quadratic: true,
      bezier: false,
      undo: true,
      lineWidth: true,
      colorsPicker: true,
      extraOptions: false,
      code: false
    });
  }

  ngOnDestroy() {
    window.onmessage = null;
    super.ngOnDestroy();
  }

  private deduplicate(array: any[]): any[] { // Failsafe to prevent local storage from growing explosively in case if everything else fails
    const emptyOperation = ['', []]; // This is the marker fot "undo" operation
    const stringifiedObjects = array.map(item => JSON.stringify(item) === JSON.stringify(emptyOperation) ?
      item : JSON.stringify(item)); // objects like ["", []] should not be treated as identical
    console.log('stringifiedObjects ', stringifiedObjects.length);
    const objectsSet = new Set(stringifiedObjects);
    console.log('objectsSet size ', objectsSet.size);
    const uniqueStringifiedObjects = Array.from(objectsSet);
    console.log('uniqueStringifiedObjects ', uniqueStringifiedObjects.length);
    return uniqueStringifiedObjects.map(item => typeof item === 'string' ? JSON.parse(item) : item);
  }

  /** The goal of using local storage is to let participants revisit room shortly after completing the call.
   * in order to see chat history, sketch, etc.
   * Saving is done for each room separately.
   * Can also help in rare test cases, when two participants have internet connection problems almost simultaneously:
   * - their internet provider is having problems;
   * - they both have bad mobile internet, which often loses connection; */

  private updateLocalStorage() { // To update local storage for room without erasing canvas data in it
    this.canvasDesignerSyncData.points = this.deduplicate(this.canvasDesignerSyncData.points);
    const roomData: any = JSON.parse(localStorage.getItem(this.userService.roomId)) || {};
    roomData['canvasData'] = {
      'canvasDesignerSyncData': this.canvasDesignerSyncData,
      'isWholeData': true
    };

    localStorage.setItem(this.userService.roomId, JSON.stringify(roomData));
  }

  private updateCanvasData(points: any[], updateAll: boolean) {
    // Updating canvas data and saving it to local storage
    // Check for 'Undo' operations when exactly "length - 1" operations are updated
    console.log('updateCanvasData points.length ', points.length);
    if (updateAll) {
      this.canvasDesignerSyncData.points = points;
    } else {
      points.forEach((point) => {
        this.canvasDesignerSyncData.points.push(point);
      });
    }
    this.updateLocalStorage();
  }

  private canvasIsNotEmpty(canvasData: any) {
    return canvasData
      && canvasData.canvasDesignerSyncData
      && canvasData.canvasDesignerSyncData.points
      && canvasData.canvasDesignerSyncData.points.length > 0;
  }

  ngAfterViewInit() {
    this.canvasDesigner.appendTo(document.getElementById('canvasWhiteboard'));
    setTimeout(() => { // Timeout is necessary, because we are appending third party widget
      this.multiLanguageService.translateToolTips(this.multiLanguageService.getCurrentLanguageString());
      const roomData: any = JSON.parse(localStorage.getItem(this.userService.roomId));
      const canvasData: any = roomData ? roomData.canvasData : null;
      if (this.canvasIsNotEmpty(canvasData)) {
        canvasData.canvasDesignerSyncData.startIndex = 0;
        this.canvasDesigner.syncData(canvasData.canvasDesignerSyncData);
        this.canvasDesignerSyncData.points = canvasData.canvasDesignerSyncData.points;
        console.log('this.canvasDesignerSyncData.points: ', this.canvasDesignerSyncData.points.length);
        console.log('Loading canvas data from local storage.');
      }
    }, 1000);

    this.streamingService
      .getMessages()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(messageData => {
        if (messageData.sender !== 'me') {
          if (messageData.message.canvasDesignerSyncData) {
            // Below we check if we got the whole canvas data or just the drawing operation
            const messagePointsLength = messageData.message.canvasDesignerSyncData.points.length;
            const emptyOperation = ['', []];
            if (messageData.message.isWholeData &&
              messagePointsLength > this.canvasDesignerSyncData.points.length) {
              console.log('Partner has more recent canvas data. Syncing canvas data to partner\'s canvas and updating local storage');
              this.canvasDesignerSyncData = messageData.message.canvasDesignerSyncData;
              this.updateLocalStorage();
            } else if (!messageData.message.isWholeData) {
              if (messageData.message.canvasDesignerSyncData.startIndex === 0 &&
                JSON.stringify(messageData.message.canvasDesignerSyncData.points[messagePointsLength - 1]) === JSON.stringify(emptyOperation)) {
                this.canvasDesignerSyncData = messageData.message.canvasDesignerSyncData;
                this.updateLocalStorage();
              } else {
                this.updateCanvasData(messageData.message.canvasDesignerSyncData.points, false);
              }
              this.canvasDesigner.syncData(messageData.message.canvasDesignerSyncData);
            }
          } else if (messageData.message.canvasWidth && messageData.message.canvasHeight) {
            try {
              // Yes, we have to set iFrame size to get scrolling work. Resizing drawing surface is not enough
              const canvasFrame: HTMLIFrameElement = document.getElementsByTagName('iframe')[0];
              canvasFrame.width = '' + Math.max(messageData.message.canvasWidth, screen.availWidth - 300);
              canvasFrame.height = '' + Math.max(messageData.message.canvasHeight, screen.availHeight - 160);
              console.log('canvasFrame.width and height ', canvasFrame.width, canvasFrame.height);
              canvasFrame.setAttribute('style', 'width: ' + canvasFrame.width + 'px; height: ' + canvasFrame.height + 'px; border: 0px;');

              // Yes, we have to set drawing surface size, because resizing iFrame doesn't change the size of canvas
              const mainCanvas: any = canvasFrame.contentWindow.document.getElementById('main-canvas');
              const tempCanvas: any = canvasFrame.contentWindow.document.getElementById('temp-canvas');
              mainCanvas.width = tempCanvas.width = canvasFrame.width;
              mainCanvas.height = tempCanvas.height = canvasFrame.height;
              if (!this.canvasResized) {
                console.log('Canvas is resized');
                this.streamingService.sendStuffP2P({
                  canvasWidth: screen.availWidth - 300,
                  canvasHeight: screen.availHeight - 160
                });
                this.canvasResized = true;
              }
            } catch (error) {
              this.canvasResized = false;
              console.log('Frame resize error: ', error);
            } finally {
              console.log('Updating canvas data after resize');
              this.canvasDesigner.syncData(this.canvasDesignerSyncData);
            }
          }
        }
      });

    this.streamingService.observePartnerId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(id => {
        if (id && id !== '') {
          setTimeout(() => { // We get partner's id slightly earlier then we are able to send messages, that's why we need timeout
            console.log('Sending the canvas size P2P ', {
              canvasWidth: screen.availWidth - 300,
              canvasHeight: screen.availHeight - 160
            });
            this.streamingService.sendStuffP2P({
              canvasWidth: screen.availWidth - 300,
              canvasHeight: screen.availHeight - 160
            });
            const opponentConnected = !!id;
            const roomData: any = JSON.parse(localStorage.getItem(this.userService.roomId));
            const canvasData: any = roomData ? roomData.canvasData : null;
            if (opponentConnected && id !== 'me' && id !== '' && this.canvasIsNotEmpty(canvasData)) {
              console.log('Partner is connected. Sending canvas data to him for synchronization.');
              this.streamingService.sendStuffP2P({
                canvasDesignerSyncData: this.canvasDesignerSyncData,
                isWholeData: true
              });
            }
          }, 1500);
        }
      });

    window.onmessage = (event) => {
      if (event.data && event.data.canvasDesignerSyncData) {
        console.log('Sending canvas data about current drawing operation.');
        this.streamingService.sendStuffP2P(event.data);
        const messagePointsLength = event.data.canvasDesignerSyncData.points.length;
        const emptyOperation = ['', []];
        if (event.data.canvasDesignerSyncData.startIndex === 0 &&
          JSON.stringify(event.data.canvasDesignerSyncData.points[messagePointsLength - 1]) === JSON.stringify(emptyOperation)) {
          // Because we are passing "updateAll" as true, we have to push "undo" operation before updating current
          // array of points stored locally
          event.data.canvasDesignerSyncData.points.push(emptyOperation);
          this.updateCanvasData(event.data.canvasDesignerSyncData.points, true);
        } else {
          this.updateCanvasData(event.data.canvasDesignerSyncData.points, false);
        }
      }
    };
  }
}

