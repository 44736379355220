import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatSliderModule,
  MatDialogModule,
  MatInputModule,
  MatTabsModule,
  MatDividerModule,
  MatSelectModule,
  MatProgressBarModule,
  MatSidenavModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from 'ngx-clipboard';
import { Routes, RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { PopupService } from './services/popup.service';
import { VideoStreamingService } from './services/video-streaming.service';
import { UserService } from './services/user.service';
import { SettingsService } from './services/settings.service';

import { AppComponent } from './app.component';
import { SettingsComponent } from './components/settings/settings.component';
import { VideochatComponent } from './components/videochat/videochat.component';
import { WaitingRoomComponent } from './components/waiting-room/waiting-room.component';
import { PopupComponent } from './components/popup/popup.component';
import { NotesComponent } from './components/notes/notes.component';
import { ChatComponent } from './components/chat/chat.component';
import { MainComponent } from './components/main/main.component';
import { ActionsBarComponent } from './components/actions-bar/actions-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { SketchBoardComponent } from './components/sketch-board/sketch-board.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { ThanksPageComponent } from './components/thanks-page/thanks-page.component';
import {WaitingRoomDesktopComponent} from './components/waiting-room/desktop/waiting-room-desktop.component';
import {WaitingRoomMobileComponent} from './components/waiting-room/mobile/waiting-room-mobile.component';
import {HeaderDesktopComponent} from './components/header/desktop/header-desktop.component';
import {HeaderMobileComponent} from './components/header/mobile/header-mobile.component';
import {VideochatDesktopComponent} from './components/videochat/desktop/videochat-desktop.component';
import {VideochatMobileComponent} from './components/videochat/mobile/videochat-mobile.component';
import {SettingsDesktopComponent} from './components/settings/desktop/settings-desktop.component';
import {SettingsMobileComponent} from './components/settings/mobile/settings-mobile.component';
import { MobileDebugService } from './services/mobile-debug.service';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';

const appRoutes: Routes = [
  {path: ':roomId/:token', component: WaitingRoomComponent},
  {path: 'room/:roomId/:token', component: MainComponent},
  {path: 'settings/:roomId/:token', component: SettingsComponent},
  {path: 'thanks', component: ThanksPageComponent}
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    SettingsDesktopComponent,
    SettingsMobileComponent,
    VideochatComponent,
    VideochatDesktopComponent,
    VideochatMobileComponent,
    WaitingRoomComponent,
    WaitingRoomDesktopComponent,
    WaitingRoomMobileComponent,
    PopupComponent,
    NotesComponent,
    ChatComponent,
    MainComponent,
    ActionsBarComponent,
    HeaderComponent,
    HeaderDesktopComponent,
    HeaderMobileComponent,
    SketchBoardComponent,
    DocumentsComponent,
    ThanksPageComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    MatSliderModule,
    MatTabsModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatDividerModule,
    ClipboardModule,
    HttpClientModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DeviceDetectorModule.forRoot(),
  ],
  providers: [
    VideoStreamingService,
    UserService,
    PopupService,
    SettingsService,
    MobileDebugService
  ],
  entryComponents: [
    PopupComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
