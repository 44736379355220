import { Component, OnDestroy, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { VideoStreamingService } from '../../../services/video-streaming.service';
import { SettingsService } from '../../../services/settings.service';
import { UserService } from '../../../services/user.service';
import { PopupService } from '../../../services/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { VideochatAbstract } from '../abstracts/videochat.abstract.component';

@Component({
  selector: 'app-videochat-mobile',
  templateUrl: './videochat-mobile.component.html',
  styleUrls: ['./videochat-mobile.component.scss']
})
export class VideochatMobileComponent
  extends VideochatAbstract
  implements OnDestroy, AfterViewInit {

  showControls = false;
  activeArea = 'videoChat';

  constructor(cdr: ChangeDetectorRef,
              public streamingService: VideoStreamingService,
              settingsService: SettingsService,
              userService: UserService,
              popupService: PopupService,
              translate: TranslateService,
              deviceDetectorService: DeviceDetectorService,
              router: Router,
              ngZone: NgZone) {
    super(cdr, streamingService, settingsService, userService, popupService, translate, deviceDetectorService, router, ngZone);

    this.streamingService
      .openArea$
      .subscribe((pageName) => {
        this.activeArea = pageName;
      });
  }

  toggleControls() {
    this.showControls = !this.showControls;
  }
}
